<template>
  <div class="he-r dynamic-margin">
    <div class="dynamic-con">
      <h5 class="he-tit">{{ title }}</h5>
      <div class="concern-box">
        <ul class="concern-list">
          <li v-for="item in concernList" :key="item.index">
			 <!-- {{item}} -->
            <div class="concer-bg" :style="{ background: 'url(' + item.webImg + ') no-repeat top center' }">
              <div class="con-bgs">
                <span @click="toHe(item)" class="concer-img" :style="{ background: 'url(' + item.webImg + ') no-repeat center' }"></span>
                <div class="concer-info">
                  <h5>
                    {{ item.trueName }}
                    <i
                      class="vip-level"
                      :style="{ background: 'url(' + level + ') no-repeat top center', backgroundSize: 'cover' }"
                    ></i>
                  </h5>
                  <div class="concer-btn">
                    <el-button class="con-btn1" @click="cancelFollow(item)">已关注</el-button>
                    <!-- <el-button class="con-btn2">发邮件</el-button> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="concer-info-con">
              <p>非遗名录：{{ item.fintangibleDir }}</p>
              <p>非遗类型：{{ item.fintangibleType }}</p>
              <!-- <p>级别：{{ item.inheritorLevel }}</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    followNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      title: '我的关注',
      level: require('../../assets/images/level.png'),
      concernList: [
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // },
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // },
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // },
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // },
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // },
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // }
      ]
    }
  },
  methods: {
    getFollowList() {
      this.axios.get('/api/portal/friend/auth/follow/page').then(res => {
        this.concernList = res.data.records
      })
    },
    cancelFollow({ inheritorId }) {
      this.axios.post('/api/portal/friend/auth/follow/remove', { inheritorId }).then(res => {
        this.$message.success('取消关注成功')
        this.getFollowList()
        this.$emit('update:followNum', this.followNum - 1)
      })
    },
	toHe(res){
		  console.log(res)
		  this.$router.push({path:"../../content/inheritor",query:{id:res.id,contentId:res.inheritorId}})
	}
  },
  mounted() {
    this.getFollowList()
    document.getElementsByTagName('body')[0].className = 'page-body'
  },
  beforeDestroy() {
    document.body.removeAttribute('class', 'page-body')
  },
  

  computed: {}
}
</script>
<style lang="scss" scoped></style>
